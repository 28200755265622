import React from "react";

const Video = () => (
	<section className="find-best">
		<div className="row">
			<div className="col-md-12">
				<div className="special-heading">
					<h2>Find the <span className="intro-heading-orange">simple and easiest</span> way to connect each other.</h2>
				</div>
			</div>
			<div className="col-md-12 col-sm-12">				
				<div className="video-container">
					<video width="100%" controls>
					  	<source src="https://www.radiantmediaplayer.com/media/big-buck-bunny-360p.mp4" type="video/mp4" />
					</video> 
				</div>
			</div>			
		</div>
	</section>	
)

export default Video