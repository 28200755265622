import React from "react"
const onClick=function(){
	window.location.href="https://app.meetnow.in";
}
const FindBest = () => (
	<section className="find-best">
		<div className="row">
			<div className="col-md-12">
				<div className="special-heading text-left">
					<h2>Choose the best suitable <span className="intro-heading-orange">Package</span> for you...</h2>
				</div>
			</div>
			<div className="col-md-4 col-sm-12">
				<div className="personal-box">
					<div className="personal-tital">
						<h4>Personal</h4>
						<p>Perfect for personal meetings</p>
					</div>
					<div className="free-ul">
						<h2>It&apos;s Free</h2>
						<ul className="list-unstyled pt-2">
							<li><a href="#">Unlimited Meetings</a></li>
							<li><a href="#">Up to 999 people</a></li>
							<li><a href="#">End to End Encrypted</a></li>
							<li><a href="#">No time limits</a></li>
							<li><a href="#">No account required</a></li>
						</ul>
						
					</div>
					<button className="btn btn-default btn-bottom" onClick={onClick}>Try for free</button>
				</div>
			</div>
			<div className="col-md-4 col-sm-12">
				<div className="personal-box primium-plan-color">
					<div className="personal-tital">
						<h4>Premium plan</h4>
						<p>Personalized plan</p>
					</div>
					<div className="free-ul">
						<h2><strike className="strike-throug-color">499 INR</strike> FREE now</h2>
						<ul className="list-unstyled pt-2">
							<li><a href="#">Everything in free</a></li>
							<li><a href="#">Free PMId's</a></li>
							<li><a href="#">Upto 300 participants</a></li>
							<li><a href="#">Schedule meetings</a></li>
							<li><a href="#">AR filters</a></li>
							<li><a href="#">1080 HD video quality</a></li>
							<li><a href="#">Live streaming to youtube</a></li>
							<li><a href="#">Limited on demand recording</a></li>
							<li><a href="#">Screen sharing</a></li>
							<li><a href="#">Chat enabled</a></li>
							<li><a href="#">Calendar integration</a></li>
							<li><a href="#">Audio only calls</a></li>
							<li><a href="#">Phone dial-in*</a></li>
							<li><a href="#">More...</a></li>
						</ul>
						
					</div>
					<button className="btn btn-default btn-bottom" onClick={onClick}>Try for free</button>
				</div>
			</div>
			<div className="col-md-4 col-sm-12">
				<div className="enterprise-box personal-box">
					<div className="enterprise-tital">
						<h4>Enterprise</h4>
						<p>Perfect for large team with a big goal</p>
					</div>
					<div className="free-ul">
						<h2>Custom</h2>
						<ul className="list-unstyled pt-2">
							<li><a href="#">Everything in plus...</a></li>
							<li><a href="#">99.99 uptime</a></li>
							<li><a href="#">Unlimited meetings and user accounts</a></li>
							<li><a href="#">Unlimited participants</a></li>
							<li><a href="#">Dedicated hosting</a></li>
							<li><a href="#">Dropbox integration</a></li>
							<li><a href="#">Developer SDKs for whitelabeling</a></li>
							<li><a href="#">Windows Applications</a></li>
							<li><a href="#">Meetnow Rooms</a></li>
							<li><a href="#">File sharing</a></li>
							<li><a href="#">Live whiteboard</a></li>
							<li><a href="#">Host and Moderator permissions</a></li>
							<li><a href="#">Email integrations</a></li>
							<li><a href="#">Slack integrations</a></li>
						</ul>
						
					</div>
					<button className="btn btn-primary mr-20 btn-bottom">Contact Us</button>
				</div>
			</div>	
		</div>
	</section>	
)

export default FindBest