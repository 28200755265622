import React from "react";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};

class Demo extends React.Component{

	constructor(props) {
	    super(props);

	    this.state = {
			fullName: null,
			email: null,
			city: null,
			errors: {
				fullName: '',
				email: '',
				city: '',
			}
	    };
		
		this.handleChange   = this.handleChange.bind(this);
		this.handleSubmit   = this.handleSubmit.bind(this);		
	}

  	handleChange = (event) => {
	    event.preventDefault();
	    const { name, value } = event.target;
	    let errors = this.state.errors;

	    switch (name) {
	      	case 'fullName': 
	        	errors.fullName = value.length < 0 ? 'Please enter your name!' : '';
	        break;
	      	case 'city':
	      		errors.fullName = value.length < 0 ? 'Please enter your city': '';
	      	break;
	      	case 'email': 
	        	errors.email =  validEmailRegex.test(value) ? '' : 'Email is not valid!';
	        break;	      
	      	default:
	       	break;
	    }

	    this.setState({errors, [name]: value});
  	}

  	handleSubmit = (event) => {
	    event.preventDefault();
	    
	    if(validateForm(this.state.errors)) {
	    	
	    	console.log(this.state.fullName);
	    	console.log(this.state.city);
	    	console.log(this.state.email);
	      	
	      	console.info('Valid Form');
	    }else{
	      console.error('Invalid Form')
	    }
	}

	render (){
		const {errors} = this.state;
		return (
			<section className="demo-section">		
				<div className="row">			
					<div className="col-md-12 col-sm-12">				
						<div className="demo-title">
							<h2>Schedule a demo</h2>
						</div>
						<div className="contact-form">
							<form className="row" onSubmit={this.handleSubmit}>
								<div className="col-md-6">
									<div className="form-group">
									    <label htmlFor="name">Your name</label>
									    <input type="text" className="form-control" name="fullName" id="name" defaultValue={this.state.name} onChange={this.handleChange} placeholder="Enter your name here" />					    
									    {errors.fullName.length > 0 &&  <span className='error'>{errors.fullName}</span>}
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
									    <label htmlFor="city">Your city</label>
									    <input type="text" className="form-control" name="city" id="city" defaultValue={this.state.city} onChange={this.handleChange} placeholder="Enter your city name" />
									    {errors.city.length > 0 &&  <span className='error'>{errors.city}</span>}
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
									    <label htmlFor="email">Email ID</label>
									    <input type="text" className="form-control" name="email" id="email" defaultValue={this.state.email} onChange={this.handleChange} placeholder="Enter your email address" />
									    {errors.email.length > 0 &&  <span className='error'>{errors.email}</span>}
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group">
									    <label htmlFor="phone">Phone number</label>
									    <input type="text" className="form-control" name="phone" id="phone" defaultValue={this.state.phone} onChange={this.handleChange} placeholder="Enter your phone number" />
									</div>
								</div>
								<div className="col-md-12 text-center">
									<button type="submit" className="btn btn-primary">Submit</button>
								</div>			
							</form>
						</div>
					</div>			
				</div>		
			</section>	
		);
	}
}

export default Demo