import React,{ useState,useEffect} from "react";
import Jitsi from 'react-jitsi';

import '../assets/bootstrap/css/bootstrap.css'; 
import '../assets/css/all.css';
import '../assets/css/style.css'; 

import Header from "../components/header";
import Intro from '../components/intro';
import Features from '../components/features';
import SpecialFeature from '../components/special-feature';
import FindBest from '../components/find-best';
import Video from '../components/video';
import Demo from '../components/demo';
import Auth from '../components/auth';
import BestConsistent from '../components/best-consisten';
import BrandedLogo from '../components/branded-logo';
import AboutUs from '../components/about-us';
import Footer from '../components/footer';
import { getUser, isLoggedIn, logout } from "../helpers/auth";




const IndexPage = () => {
    const { displayName, email } = getUser();
    return (
        <div>
            <Header displayName={displayName}/> 
            <div className="container">                  
                <Intro/>
                <Features/>
                <FindBest/>
                <Video/>                
            </div>
            <Demo/>
            {!displayName && <Footer/>}
        </div>
    )
}
export default IndexPage