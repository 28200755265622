import React from "react"

import quadImage1 from '../assets/img/quad-img-1.jpg';
import quadImage2 from '../assets/img/quad-img-2.jpg';
import quadImage3 from '../assets/img/quad-img-3.jpeg';
import quadImage4 from '../assets/img/quad-img-4.jpg';

const Features = () => (
	<section className="features-section">
		<div className="features-heading">
			<h2>Stay home and enjoy the best teamwork <br/> experience by <span className="intro-heading-orange">MeetNow</span></h2>
		</div>
		<div className="row feature-images">
			<div className="col-md-6 col-sm-12 feature-img">
				<img src={quadImage1} alt="feature-1" />
				<div className="feature-overlay">
					<h4 className="feature-hover-heading intro-heading-orange">File Sharing</h4>
					<p className="feature-hover-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus, </p>
				</div>
			</div>
			<div className="col-md-6 col-sm-12 feature-img">
				<img src={quadImage2} alt="feature-1" />
				<div className="feature-overlay">
					<h4 className="feature-hover-heading intro-heading-orange">File Sharing</h4>
					<p className="feature-hover-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus, </p>
				</div>
			</div>
			<div className="col-md-6 col-sm-12 feature-img">
				<img src={quadImage3} alt="feature-1" />
				<div className="feature-overlay">
					<h4 className="feature-hover-heading intro-heading-orange">File Sharing</h4>
					<p className="feature-hover-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus, </p>
				</div>
			</div>
			<div className="col-md-6 col-sm-12 feature-img">
				<img src={quadImage4} alt="feature-1" />
				<div className="feature-overlay">
					<h4 className="feature-hover-heading intro-heading-orange">File Sharing</h4>
					<p className="feature-hover-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Natus, </p>
				</div>
			</div>
		</div>
	</section>
)

export default Features