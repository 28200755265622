import React,{ useState,useEffect} from "react";
import Jitsi from 'react-jitsi';

import Work13 from '../assets/img/homescreenIllustration.svg'; 
import appStoreBtn from '../assets/img/app-store.png';
import playStoreBtn from '../assets/img/play-store.png';

import { getUser, isLoggedIn, logout } from "../helpers/auth";


const Intro = () => {
	
	const [user, setUser]         = useState();
	const { displayName, email }  = getUser();
	const [roomName, setRoomName] = useState('');
	const [password, setPassword] = useState('');

	const [onCall, setOnCall]     = useState(false);

	const login=function(){}
	return(
    <section className="intro">
			<div className="row">
				<div className="col-lg-6 col-sm-12">
					<h1 className="intro-heading intro-heading-orange">MeetNow,</h1>
					<h1 className="intro-heading">India’s own conferencing solution</h1>
					<p className="intro-text">Simple and secure connections for everyone. Free!</p>

					<a href="https://apps.apple.com/us/app/meetnow-indias-conference/id1510590657" className="store-links">
						<img className="store-btn app-store" src={appStoreBtn}/>
					</a>
					<a href="https://play.google.com/store/apps/details?id=in.meetnow" className="store-links">
						<img className="store-btn play-store" src={playStoreBtn}/>
					</a>
				</div>
				<div className="col-lg-6 col-sm-12">
					{roomName && 
						<Jitsi  roomName={roomName} 
						password={password} 
						displayName={displayName} 
						onAPILoad={JitsiMeetAPI => console.log('Good Morning everyone!')}
						domain="app.meetnow.in"/>}
					{!displayName && <div className="intro-thumbnail">
						<img className="img-fluid" src={Work13} alt="Work13"/>
					</div>
					}
				</div>
			</div>
		</section>
	)
}

export default Intro